






















































































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxEditing,
  DxLookup,
  DxPosition,
  DxForm,
  DxGroupPanel,
  DxSearchPanel,
  DxHeaderFilter,
  DxFormItem,
  DxExport,
  DxGrouping,
  DxFilterRow,
  DxValidationRule,
  DxPager,
  DxSummary,
  DxTotalItem,
  DxColumnChooser,
  DxStateStoring,
  DxPopup
} from "devextreme-vue/data-grid";

import { ClassColumnDataTable } from "./ClassColumnDataTable";
import {
  EventAddClick,
  EventEditClick,
  EventRemoveClick,
  EventRefresh,
  EventEditingStart,
  EventCreatingStart,
  EventEditorPreparing,
  EventOnContextMenuPreparing,
  EventEditButton
} from "./data-table-events";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxEditing,
    DxLookup,
    DxPosition,
    DxForm,
    DxGroupPanel,
    DxSearchPanel,
    DxHeaderFilter,
    DxFormItem,
    DxExport,
    DxGrouping,
    DxFilterRow,
    DxValidationRule,
    DxSummary,
    DxTotalItem,
    DxColumnChooser,
    DxStateStoring,
    DxPopup
  }
})
export default class DataTable extends Vue {
  @Ref("gridRefName") VisualDxDataGrid!: DxDataGrid;
  @Prop({
    default: "id",
    type: String,
    required: false
  })
  identificador!: string;
  @Prop({
    default: 20,
    type: Number,
    required: false
  })
  pagesize!: string;
  @Prop({
    default: false,
    type: Boolean,
    required: false
  })
  AllowExportSelectedData!: boolean;
  @Prop({
    default: true,
    type: Boolean,
    required: false
  })
  HighlightCaseSensitive!: boolean;
  @Prop({ default: false, type: Boolean, required: false })
  allowUpdating!: boolean;
  @Prop({ default: false, type: Boolean, required: false })
  allowAdding!: boolean;
  @Prop({ default: false, type: Boolean, required: false })
  allowDeleting!: boolean;
  @Prop({ default: false, type: Boolean, required: false })
  PopupEditing!: boolean;
  @Prop({
    default: true,
    type: Boolean,
    required: false
  })
  showBorders!: boolean;

  @Prop({
    required: true
  })
  DataSource!: any;

  @Prop({
    required: true
  })
  Columns!: ClassColumnDataTable[];
  @Prop({
    default: false,
    type: Boolean,
    required: false
  })
  buttonsEdit!: boolean;
  @Prop({
    default: false,
    type: Boolean,
    required: false
  })
  buttonEdit!: boolean;
  @Prop({
    default: false,
    type: Boolean,
    required: false
  })
  buttonPrint!: boolean;
  @Prop({
    default: false,
    type: Boolean,
    required: false
  })
  buttonMoreOptions!: boolean;

  @Prop({
    default: false,
    type: Boolean,
    required: false
  })
  buttonDelete!: boolean;
  @Prop({
    default: false,
    type: Boolean,
    required: false
  })
  buttonClone!: boolean;

  @Prop({
    default: 525,
    type: Number,
    required: false
  })
  width!: number;
  @Prop({
    default: 700,
    type: Number,
    required: false
  })
  height!: number;
  public focusedRowKey: number = 0;
  public focusedRowIndex: number = 0;

  public get editButtons() {
    let btns = [];
    if (this.buttonEdit) {
      btns.push({
        hint: "Editar",
        icon: "edit",
        visible: true,
        onClick: () => this.$emit(EventEditButton, this.RowIndex())
      });
    }
    if (this.buttonPrint) {
      btns.push({
        hint: "Imprimir",
        icon: "print",
        visible: true,
        onClick: () => this.$emit("EventPrintButton", this.RowIndex())
      });
    }

    if (this.buttonDelete) {
      btns.push({
        hint: "Eliminar",
        icon: "trash",
        visible: true,
        onClick: () => this.$emit("EventDeleteButton", this.RowIndex())
      });
    }

    if (this.buttonClone) {
      btns.push({
        hint: "Clonar",
        icon: "repeat",
        visible: true,
        onClick: () => this.$emit("EventCloneButton", this.RowIndex())
      });
    }
    if (this.buttonMoreOptions) {
      btns.push({
        hint: "Mas opciones",
        icon: "overflow",
        visible: true,
        onClick: () => this.$emit("EventMoreOptionsButton", this.RowIndex())
      });
    }
    return btns;
  }

  public customValidationCallback(event: any): boolean {
    event.rule.isValid = false;
    event.rule.message = "Valor no valido " + event.column.caption;
    for (var _i = 0; _i < this.Columns.length; _i++) {
      if (event.column.dataField == this.Columns[_i].datafield) {
        if (this.Columns[_i].Required) {
          let valido: boolean = false;
          try {
            valido = event.value.toString().trim() !== "";
          } catch (error) {}

          event.rule.isValid = valido;
          if (!valido) {
            event.rule.message = "Valor no valido " + event.column.caption;
          } else {
            event.rule.message = "";
          }
          return valido;
        }
      }
    }
    return true;
  }

  public nuevaRow(e: any) {
    this.$emit(EventAddClick, e);
  }

  public EliminarRow(e: any) {
    this.$emit(EventRemoveClick, e);
  }

  public UpdateRow(e: any) {
    this.$emit(EventEditClick, e);
  }
  public AbroPopup(e: any) {
    this.$emit(EventEditingStart, e);
  }
  public AbroPopupParaCrear(e: any) {
    this.$emit(EventCreatingStart, e);
  }
  public Refrescar() {
    this.$emit(EventRefresh);
  }
  public editorPreparing(e: any) {
    this.$emit(EventEditorPreparing, e);
  }
  public onContextMenuPreparing(e: any) {
    if (e.target === "content" && e.rowIndex >= 0 && e.row.rowType === "data") {
      //No eliminar así sabemos como crear un menu conceptual al hacer click derecho en una row
      /*e.items = [
        {
          text: i18n.t("addnuevafila"),
          icon: "add",
          onItemClick: function() {
            e.component.addRow(e.row.values);
          }
        }
      ];*/
      this.$emit(EventOnContextMenuPreparing, e);
    }
  }

  public onRowDblClick(e: any) {
    this.$emit(EventEditClick, e);
    this.$emit(EventEditButton, this.RowIndex());
  }

  public onEditorPreparing(e: any) {
    //https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxForm/Item_Types/SimpleItem/#editorOptions
    if (e.parentType == "dataRow") {
      for (var _i = 0; _i < this.Columns.length; _i++) {
        if (e.dataField == this.Columns[_i].datafield) {
          e.editorOptions.maxLength = this.Columns[_i].maxLength;
        }
      }
    }
  }
  public GetRefGrid(e: any) {
    var Grid = this.$refs.gridRefName as any;
    Grid = Grid.instance;
    Grid.clearGrouping();
  }
  public RowKey(): string {
    let dtg: any = this.DxDataGrid;
    let rowKey = dtg.focusedRowKey;
    return UtilsString.ValueOfValue(dtg.instance.getKeyByRowIndex(rowKey));
  }

  public RowIndex(): string {
    let dtg: any = this.DxDataGrid;
    let rowIndex = dtg.focusedRowIndex;
    return UtilsString.ValueOfValue(dtg.instance.getKeyByRowIndex(rowIndex));
  }
  public DataForRow(): any[] {
    let dtg: any = this.DxDataGrid;
    return dtg.instance.getRowElement(this.RowIndex());
  }
  public get displayFormatSummary() {
    return "núm. registros {0}";
  }
  public get DxDataGrid() {
    return this.VisualDxDataGrid;
  }
  public get pageSizes() {
    return [10, 20, 40, 60, 80, 100];
  }
  public get ShowSumaryIn() {
    for (let i = 0; i < this.Columns.length; i++) {
      if (this.Columns[i].visible) {
        return this.Columns[i].datafield;
      }
    }
  }
}
